const AgeGate = (($) => {
    class AgeGate {
        
        constructor(node) {
            this.$gate = $(node);
            this.$message = this.$gate.find('.message');
            this.storage = this.getStorageSettings();
            this.$trigger = $('form', this.$gate);
        }

        getStorageSettings() {
            const settings = {
                key: 'curiowellness_agegate', // Key for the cookie
            };

            settings.expirationKey = `${settings.key}_expiration`; // Expiration key for the cookie

            return settings;
        }

        showModal() {
            this.$gate.addClass('active');
            return this;
        }

        hideModal() {
            this.hideMessage();
            this.$gate.removeClass('active');
        }

        hideMessage() {
            this.$message.slideUp();
            return this;
        }

        showMessage() {
            this.$message.slideDown();
            return this;
        }

        onSubmit(event) {
            event.preventDefault();
            const form = event.target;

            // Get the values from the birthMonth, birthDay, and birthYear select elements
            const birthMonth = $(form).find('#birthMonth').val();
            const birthDay = $(form).find('#birthDay').val();
            const birthYear = $(form).find('#birthYear').val();

            if (!birthMonth || !birthDay || !birthYear) {
                this.showMessage();  // Show error if any field is missing
                return;
            }

            // Create a date string from the selected values
            const dateOfBirthValue = `${birthYear}-${birthMonth}-${birthDay}`;

            // Check if the user is 21 or older
            const isTwentyOneOrOlder = this.checkIfTwentyOneOrOlder(dateOfBirthValue);
            
            if (!isTwentyOneOrOlder) {
                this.showMessage(); 
                return;
            }

            // Store the age gate verification status in cookies for 7 days
            this.store();

            // Hide the modal if the user is 21 or older
            this.hideModal();
            
            return this;
        }

        checkIfTwentyOneOrOlder(dateOfBirthValue) {
            // Parse the input date
            const birthDate = new Date(dateOfBirthValue);
            const today = new Date();
        
            // Calculate the age
            let age = today.getFullYear() - birthDate.getFullYear();
            const month = today.getMonth() - birthDate.getMonth();
            if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
                age--;  // Adjust age if the birthday hasn't occurred this year yet
            }
            
            return age >= 21;  // Return true if 21 or older, otherwise false
        }

        store() {
            // Store the age verification in a cookie
            this.setCookie(this.storage.key, 1, 7);  // Set age gate cookie for 7 days
            this.setCookie(this.storage.expirationKey, this.getStoreExpiration(), 7); // Set expiration cookie for 7 days

            return this;
        }

        setCookie(name, value, days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));  // Set expiration in days
            const expires = `expires=${date.toUTCString()}`;
            document.cookie = `${name}=${value};${expires};path=/`;
        }

        getCookie(name) {
            const nameEQ = `${name}=`;
            const ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) === ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        }

        verify() {
            if (this.getCookie(this.storage.key)) return true;
            if (!this.hasCookieExpired()) return true;

            this.clearStorage();
            this.showModal();
            return false;
        }

        clearStorage() {
            this.setCookie(this.storage.key, '', -1);  // Remove the age gate cookie
            this.setCookie(this.storage.expirationKey, '', -1);  // Remove the expiration cookie

            return this;
        }

        hasCookieExpired() {
            const expiration = this.getCookie(this.storage.expirationKey);
            if (!expiration) return true;

            const curDate = new Date().getTime();
            const expirationDate = parseInt(expiration, 10);

            return curDate >= expirationDate;
        }

        getStoreExpiration() {
            const date = new Date();
            date.setDate(date.getDate() + 7);  // Set expiration to 7 days from now
            return date.getTime();
        }

        init() {
            this.$trigger.on('submit', this.onSubmit.bind(this));        
            this.verify();
        }
    }

    return {
       init({ selector } = {}) {
          return jQuery(selector).map((index, node) => {
             const module = new AgeGate(node);
             module.init();
          });
        },
    };
})(window.jQuery);

(($) => {
    AgeGate.init({
       selector: '.age-gate',
    });
})(window.jQuery);
