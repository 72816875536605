if('wp' in window && 'blocks' in wp) {
    wp.hooks.addFilter(
        'blocks.registerBlockType',
        'curio/blocks',
        function( settings, name ) {

            if ( name === 'basejump-support/tabs' && settings.supports.hasOwnProperty('templates') ) {
                console.log(name);
                settings.supports.templates.pop()
                settings.supports.templates = [
                    ...settings.supports.templates,
                    {
                        name: "accordion",
                        label: "Accordion"
                    },
                    {
                        name: "faq",
                        label: "FAQ"
                    }
                ]
            }
            return settings;
        }
    );
}